@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.global-announcement-banner {
  animation: slideDown 0.5s ease forwards;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-black);
  flex-shrink: 0;
  padding: 8px 16px;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.37, 0.01, 0, 0.98) 0s;
  &-content {
    text-align: center;
    a p {
      margin: 0;
      a {
        color: var(--color-primary-white);
      }
    }
  }
  &:hover {
    background-color: rgb(21, 30, 53);
  }
  @media only screen and (max-width: 480px) {
    &-content {
      font-size: 14px;
    }
  }
}
